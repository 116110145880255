import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -0.3%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
  }

  a {
    width: 100%;
    flex: 0.85;
    &:first-child {
      flex: 1.27;
    }
    &:last-child {
      flex: 1.27;
    }
  }
`

export const SelectedToYou = styled.div`
  display: flex;
  width: 100%;
  margin: -0.3% 0;
`
