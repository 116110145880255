import { CustomLink } from '../../CustomLink'
import * as S from './styles'

const OctuberPink: FCC = () => {
  return (
    <S.Container>
      <CustomLink to="/">
        <img
          src="https://miess.vtexassets.com/arquivos/outubrorosa.jpg"
          alt="Banner outubro rosa"
        />
      </CustomLink>
      <S.SelectedToYou>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-1.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-2.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-3.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-4.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-5.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
        <CustomLink to="/">
          <img
            src="https://miess.vtexassets.com/arquivos/selecionados-6.jpg"
            alt="Banner selecionados pra você"
          />
        </CustomLink>
      </S.SelectedToYou>
      <CustomLink to="/">
        <img
          src="https://miess.vtexassets.com/arquivos/compre-ja.gif"
          alt="Banner compre já"
        />
      </CustomLink>
    </S.Container>
  )
}

export default OctuberPink
