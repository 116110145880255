import OctuberPink from 'src/components/common/Institutional/OctoberPink'
import Newsletter from 'src/components/sections/Newsletter'

const OutubroRosa: FCC = () => {
  return (
    <>
      <OctuberPink />
      <Newsletter />
    </>
  )
}

export default OutubroRosa
